<template>
    <div id="targetPersonDetail">
        <CSDialog
            dialog-title="查看个人档案"
            dialog-width="1025px"
            dialog-header-class="alert-bg"
            :dialog-visible="targetPersonDetailVisible"
            @onClose="targetPersonDetailVisible = false"
            :dialog-show-confirm-btn="false"
            dialog-cancel-btn-text="关闭"
        >
            <template v-slot:dialog-content>
                <div
                    style="max-height:969px;font-size:24px;color:#000;">
                    <div class="target_space" style="padding:30px;border-bottom:1px solid #F0F0F0;display:flex;">
                        <img :src="targetPersonInfo.faceUrl" width="200px" height="200px" alt=""/>
                        <div class="targetInformation">
                            <span>姓名：{{ targetPersonInfo.name || '-' }}</span>
                            <span>身份：{{ identityType[targetPersonInfo.type] || '-' }}</span>
                            <span>手机号：{{ targetPersonInfo.phone || '-' }}</span>
                            <span>微信号：{{ targetPersonInfo.wx || '-' }}</span>
                            <span>是否为关注人：{{ isAttentionStr[targetPersonInfo.isAttention] || '-' }}</span>
                            <span>所属企业：{{ targetPersonInfo.company || '-' }}</span>
                            <span>所在房间：{{ targetPersonInfo.address || '-' }}</span>
                        </div>
                    </div>
                    <div style="padding:30px;position:relative;">
                        <div style="padding-bottom:20px;line-height: 1">
                            <div style="margin-right:40px;vertical-align: middle;display: inline-block">行动轨迹</div>
                            <div style="vertical-align: middle;display: inline-block">
                                <span style="vertical-align: middle;">显示近</span>
                                <div style="vertical-align: middle;display: inline-block; margin: 0 10px;">
                                    <CSSelect iWidth="36px" height="40px">
                                        <select style="width: 100px;" v-model="dataTime" @change="queryMovement()">
                                            <!--                                    <option value="">天</option>-->
                                            <option
                                                v-for="time in timeList"
                                                :key="time"
                                                :value="time"
                                            >
                                                {{ time }}天
                                            </option>
                                        </select>
                                    </CSSelect>
                                </div>
                                <span style="vertical-align: middle;">数据</span>
                            </div>
                        </div>
                        <div class="trail_detail" @scroll="loadActivityTrack">
                            <div class="trail_content">

                                <div class="trail" v-for="(action, index) in actionTrajectory" :key="action.id">
                                    <p :class="['time', {'active': index === 0}]">{{ action.date }}</p>
                                    <p>{{ action.cameraNo }} - {{ action.location }}</p>
                                    <div style="margin-top:10px;">
                                        <img :src="action.photoUrl" @click="lookPicture(action.photoUrl)" width="80px"
                                             height="80px" alt=""/>
                                        <!--                                    <span
                                                                                class="allow-click"
                                                                                style="margin-left:20px"
                                                                                @click="checkVideo()"
                                                                            >
                                                                                录像
                                                                            </span>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>
        <TargetPersonVideo></TargetPersonVideo>
        <ViewImageModal></ViewImageModal>
    </div>
</template>

<script>
import CSDialog from "@/components/common/CSDialog";
import CSSelect from "@/components/common/CSSelect";
import TargetPersonVideo from "@/components/SecurityCamera/targetPersonVideo"
import {queryFileByIdUrl, queryMovementUrl} from "@/requestUrl";
import ViewImageModal from "@/components/ViewImageModal";

export default {
    name: "targetPersonDetail",
    components: {
        ViewImageModal,
        CSDialog,
        CSSelect,
        TargetPersonVideo,
    },
    data() {
        return {
            identityType: {
                1: '陌生人',
                2: '物业员工',
                3: '楼内用户',
                4: '访客',
            },
            isAttentionStr: {
                0: '否',
                1: 'VIP',
                2: '可疑人',
            },
            targetPersonDetailVisible: false,
            dataTime: 3,
            timeList: [3, 7, 15, 30, 45, 60, 90],
            targetPersonInfo: {},
            subscribeId: '',
            actionTrajectory: [],
            loadTimer: null,
        }
    },
    created() {
        // 查看档案
        this.$vc.on(this.$route.path, "faceCapture", "checkFile", id => {
            this.targetPersonDetailVisible = true;
            this.dataTime = 3;
            this.subscribeId = id;
            this.queryRecord(id);
            this.queryMovement();
        });
    },
    methods: {
        // 加载活动轨迹
        loadActivityTrack(e) {
            const MIN_TRAIL_HEIGHT = 180,  // 一条行动轨迹的最低高度
                {firstChild, clientHeight, scrollTop} = e.target;
            if (this.loadTimer) {
                clearTimeout(this.loadTimer);
                this.loadTimer = null;
            }
            this.loadTimer = setTimeout(() => {
                if (firstChild.clientHeight - clientHeight - scrollTop < MIN_TRAIL_HEIGHT) {
                    this.queryMovement(this.actionTrajectory[this.actionTrajectory.length - 1].id);
                }
            }, 200)
        },
        checkVideo() {
            this.$vc.emit(this.$route.path, "targetPersonDetail", "checkVideo", true);
        },
        lookPicture(picSrc) {
            this.$vc.emit(this.$route.path, "viewImageModal", "view", picSrc);
        },
        /**
         * 查询关注人档案
         * @param {Number} id 关注人id
         * */
        queryRecord(id) {
            this.$fly.get(queryFileByIdUrl, {
                id
            })
                .then(res => {
                    if (res.code === 0)
                        this.targetPersonInfo = res.data;
                })
        },
        /**
         * 获取行动轨迹
         * @param {Number} lastId 最后一条数据的id
         * @param {Number} pageSize 分页
         * */
        queryMovement(lastId, pageSize = 5) {
            this.$fly.post(queryMovementUrl, {
                regionCode: this.$vc.getCurrentRegion().code,
                id: this.subscribeId,
                day: this.dataTime,
                leastId: lastId,
                total: pageSize,
            })
                .then(res => {
                    if (res.code !== 0) {
                        return;
                    }
                    if (lastId) {
                        this.actionTrajectory.push(...res.data);
                    } else {
                        this.actionTrajectory = res.data;
                    }
                })
        }
    }
}
</script>

<style scoped>
#targetPersonDetail .targetInformation {
    display: inline-block;
    margin-left: 30px;
    width: 730px;
    height: 200px;
}

#targetPersonDetail .targetInformation span {
    display: inline-block;
    width: 340px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 4px 0;
    margin-right: 40px;
}

#targetPersonDetail .targetInformation span:nth-of-type(even) {
    /*width: 400px;*/
    margin-right: 0;
}

/* #targetPersonDetail .trail_detail .trail_nav .point{
    display:inline-block;
    width:16px;
    height:16px;
    border-radius:50%;
    background-color:#1ab394;
} */
/* #targetPersonDetail .trail_detail .trail_nav .line{
    display:inline-block;
    width:2px;
    height:var(---line-height , 170px);
    background-color:#999999;
} */


.trail_detail {
    height: 400px;
    overflow-y: auto;
}

.trail {
    padding-left: 30px;
    position: relative;
}

.trail .time {
    position: relative;
    line-height: 1;
}

.trail .time::before {
    position: absolute;
    left: -30px;
    content: " ";
    top: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #999999;
}


.trail .time.active::before {
    background: #00B694;;
}


.trail:not(:last-of-type) {
    padding-bottom: 20px;
}

.trail:not(:last-of-type)::before {
    position: absolute;
    content: " ";
    top: 16px;
    left: 8px;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background: #999;
}
</style>
