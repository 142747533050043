<template>
    <div id="targetPersonVideo">
        <CSDialog
            dialog-title="录像"
            dialog-width="1020px"
            dialog-header-class="alert-bg"
            :dialog-visible="targetPersonVideoVisible"
            @onClose="targetPersonVideoVisible = false"
            :dialog-show-confirm-btn="false"
            dialog-cancel-btn-text="关闭"
        >
            <template
                v-slot:dialog-content
            >
                <div style="padding:30px;">
                    <video width="960px" height="560px" src="" controls style="background:rgba(0,0,0,.7)"></video>
                </div>
            </template>
        </CSDialog>
    </div>
</template>

<script>
import CSDialog from "@/components/common/CSDialog"
export default {
    components:{
        CSDialog,
    },
    data(){
        return{
            targetPersonVideoVisible: false,
        }
    },
    created(){
        this.$vc.on(this.$route.path, "targetPersonDetail","checkVideo",(data)=>{
            this.targetPersonVideoVisible = data;
        });
        this.$vc.on(this.$route.path, "cameraVideo","checkVideo",(data)=>{
            this.targetPersonVideoVisible = data.boolean;
        }),
        this.$vc.on(this.$route.path, "videoSecondDetail","checkVideo",data=>{
            this.targetPersonVideoVisible = data.boolean;
        }),
        this.$vc.on(this.$route.path, "abnormalManage","checkFile",data=>{
            this.targetPersonVideoVisible = data.boolean;
        }),
        this.$vc.on(this.$route.path, "cameraManage","checkVideo",data=>{
            this.targetPersonVideoVisible = data.boolean;
        })
    },
    methods:{

    }
}
</script>

<style>

</style>
